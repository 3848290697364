<template>
  <div :class="{'mobile': $mq == 'mobile'}">
    <b-form-row class="top-row">
      <b-col>
        <b-row>
          <b-col>
            <span class="title-up">ONGOING LIVING UPDATE OF </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="title-19">COVID-19 </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="title-thop pb-4">THERAPEUTIC OPTIONS </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="summ">Summary of evidence. Rapid review</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="divider border border-dark my-3"></div>
            <div v-if="date" class="d-flex flex-column date">
              <span class="fw-bold">See the latest publication in the PAHO database</span>
              <span class="">Last update on <strong>{{date.toLocaleDateString("en-US", options)}}</strong><a href="https://iris.paho.org/handle/10665.2/52719" class="ms-2" target="_blank"><font-awesome-icon icon="external-link-alt" class="text-dark"/></a></span>
            </div>
            <span v-else class="date loading"> Loading latest publication information <div class="mx-3 la-ball-circus la-md text-secondary d-inline-block"><div v-for="n in 5" :key="n"></div></div> </span>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>

    <b-row class="p-4 middle-row mx-0">

      <b-col class="py-auto" cols="12" md="8" order="1" order-md="0">
        <b-row>
          <h1 class="mr-desc">View the efficacy and safety of potential interventions and treatments compared to standard of care.</h1>

          <h1 v-if="studies && total_interventions" class="mr-desc"> The living review includes <strong>{{total_interventions}} interventions</strong> and <strong>{{studies}} studies.</strong></h1>
          <h1 v-else class="mr-desc"> Loading studies information <div class="mx-3 la-ball-circus la-md text-secondary d-inline-block"><div v-for="n in 5" :key="n"></div></div> </h1>
        </b-row>
        <b-row>
          <b-col cols="12" sm="4">
            <b-button pill class="mr-button w-100" :class="{'w-100': $mq == 'mobile'}" @click="$router.push('summary')">View summary table</b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col order="0" order-md="1"  :class="{'mb-5': $mq == 'mobile'}">
        <b-img class="mr-map" :src="require('../assets/images/Group16.png')" fluid center></b-img>
      </b-col>
    </b-row>

    <b-form-row class="p-4">
      <b-col>
        <SingularIsof />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import SingularIsof from '@/components/SingularIsof.vue'

export default {
  name: 'Home',
  components: {
    SingularIsof
  },
  data() {
    return {
      options: { year: 'numeric', month: 'long', day: 'numeric' },
    }
  },
  computed: {
    total_interventions() {
      return this.$store.state.total_interventions
    },
    studies() {
      return this.$store.state.studies
    },
    date() {
      return this.$store.state.date
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
.middle-row {
  background-color: #ececec;
  padding: 20vh 1rem;

  .mr-desc-bold {
    font-weight: 700;
    margin-top: 5vh;
    margin-bottom: 4vh;
    padding-right: 10vw;
    padding-left: 3vw;
  }

  .mr-bold {
    font-weight: 700;
  }

  .mr-desc {
    margin-top: 5vh;
    margin-bottom: 4vh;
    padding-right: 10vw;
    padding-left: 3vw;
  }

  .mr-button {
    margin-left: 2vw;
    padding: 15px 30px;
    background-color: $lsr-green;
    border-color: $lsr-green;
  }

  .mr-map {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.top-row {
  padding: 30vh 3vw;
  background-image: 
    linear-gradient(
      rgba(139, 207, 233, 0.65), 
      rgba(139, 207, 233, 0.65)
    ),
    url(../assets/images/jumbotron.png);
  background-size: cover;

  .title-19 {
    color: #ff4d00;
    font-size: 6.3rem;
    font-weight: 600;
    line-height: 0.7;
  }

  .title-thop {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1;
  }

  .title-up {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1;
  }
  .divider{
    max-width: 395px;
  }
  .date {
    font-size: 17px;
  }

  .summ {
    line-height: 1.6;
    font-size: 1.6rem;
  }
}

.mobile {
  .top-row {
    background-position: -150px 0px;
    .title-thop {
      font-size: 1.4rem;
      line-height: 1.8;
    }
    .title-19 {
      font-size: 4rem;
      line-height: 0.7;
    }
    .title-up {
      font-size: 1.6rem;
      line-height: 1;
    }
    .divider{
      width: 85vw;
    }
    .summ {
      line-height: 1.6;
      font-size: 1.6rem;
    }
  }
  .middle-row {
    .mr-desc {
      font-size: 2em;
      margin-top: initial;
      margin-bottom: 1em;
    }
    .mr-map {
      // position: relative;
      // top: 50%;
      // transform: translateY(-50%);
      max-height: 80px;
      margin-top: 2em;
      margin-bottom: 2em;
    }

  }

}

</style>
