<template>
  <div class="home-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <ul class="list-unstyled">
            <li><router-link to="/terms-and-conditions">Terms and conditions</router-link></li>
            <li><router-link to="/privacy-policy">Privacy policy</router-link></li>
            <li><router-link to="" v-b-modal.contact_modal>Contact</router-link></li>
          </ul>
        </div>
        <div class="col-12 col-lg-4 offset-lg-4">
          <p>Powered by</p>
          <a href="https://foundation.epistemonikos.org/" target="_blank"><img class="home-logo" style="height: 25px" src="../assets/images/logo_epistemonikos_text_white.svg" onerror="this.onerror=null; this.src='../assets/images/logo_epistemonikos_text.png'" alt="Licence Creative Commons"></a>
          <img class="img-fluid ops-logo d-md-none" :src="require(`../assets/images/ops_logo_${'en'}.svg`)" alt="Pan American Health Organization logo" />
        </div>
      </div>
    </div>
    <b-modal id="contact_modal" title="Contact" cancel-disabled>
      <template #modal-header="{ close }">
        <h5>Contact</h5>
        <button type="button" class="btn-close" @click="close()"></button>
      </template>
      <p class="my-4">
        <template>
          <p>For questions or inquiries please contact us at <a href="mailto:bir.online@paho.org">bir.online@paho.org</a></p>
        </template>
      </p>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
  .home-footer {
    // position: absolute;
    // height: 120px;
    // right: 0;
    // bottom: 0px;
    // left: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    color: $white;
    z-index: 2;
    background: $ops_green;
    .row {
      background: $ops_green;
      font-size: 0.8em;
      text-align: left;
      color: $green-font;
    }
    a, p {
      color: $white;
      text-decoration: none;
      font-size: 1.2em;
    }
    ul {
      margin-top: 10px;
    }
    .ops-logo{
      transform: translate(-6vw, -5px);
    }
  }

</style>
