import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lastUpdate: new Date(-8640000000000000),
    interventions: [],
    interventionsoutcomes: {},
    placebo: {},
    isofs: {},
    bottom_lines: {},
    studies: 200,
    date: null,
    references: [],
    lastUpdateRef: new Date(-8640000000000000),
    total_interventions: 0,
  },
  mutations: {
    SET_LAST_UPDATE(state) {
      state.lastUpdate = new Date();
    },
    SET_LAST_UPDATE_REFERENCES(state) {
      state.lastUpdateRef = new Date();
    },
    SET_INTERVENTIONS(state, interventions) {
      state.interventions = interventions;
    },
    SET_INTERVENTIONSOUTCOMES(state, interventionsoutcomes) {
      state.interventionsoutcomes = interventionsoutcomes;
    },
    SET_PLACEBO(state, placebo) {
      state.placebo = placebo;
    },
    SET_ISOFS(state, isofs) {
      state.isofs = isofs;
    },
    SET_BOTTOM_LINES(state, bottom_lines) {
      state.bottom_lines = bottom_lines
    },
    SET_DATE(state, date) {
      state.date = new Date(date)
    },
    SET_STUDIES(state, studies) {
      state.studies = studies
    },
    SET_TOTAL_INTERVENTIONS(state, total_interventions) {
      state.total_interventions = total_interventions
    },
    SET_REFERENCES(state, references) {
      state.references = references
    }
  },
  actions: {
    readSpreadsheet({ commit, state }) {
      const ONE_HOUR = 60 * 60 * 1000
      const NOW = new Date()
      if (NOW - state.lastUpdate > ONE_HOUR) {
       return axios.get(process.env.VUE_APP_API_URL + '/OPS/isof')
            .then(response => {
                commit('SET_LAST_UPDATE')
                commit('SET_INTERVENTIONS', response.data.interventions)
                commit('SET_INTERVENTIONSOUTCOMES', response.data.interventionsoutcomes)
                commit('SET_PLACEBO', response.data.placebo)
                commit('SET_ISOFS', response.data.isofs)
                commit('SET_BOTTOM_LINES', response.data.bottom_lines)
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        return new Promise((resolve) => { resolve(null); })
      }
    },
    readExtraData({ commit, state }) {
      const ONE_HOUR = 60 * 60 * 1000
      const NOW = new Date()
      if (NOW - state.lastUpdate > ONE_HOUR) {
       return axios.get(process.env.VUE_APP_API_URL + '/OPS/date')
            .then(response => {
                commit('SET_LAST_UPDATE')
                commit('SET_DATE', response.data.last_update)
                commit('SET_STUDIES', response.data.studies)
                commit('SET_TOTAL_INTERVENTIONS', response.data.interventions)
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        return new Promise((resolve) => { resolve(null); })
      }
    },
    readReferences({ commit, state }) {
      const ONE_HOUR = 60 * 60 * 1000
      const NOW = new Date()
      if (NOW - state.lastUpdateRef > ONE_HOUR) {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
        }
       return axios.get(
        'https://sheets.googleapis.com/v4/spreadsheets/1fUX-SgxLhr08YuwH1RoBG7wqT3mmkrwMAdi1MnX8sGE/values/References!A1:AZ9999', config
        )
            .then(res => {
                commit('SET_LAST_UPDATE_REFERENCES')
                let references = []
                for (let i = 0 ; i < res.data.values.length; i++) {
                  if (res.data.values && res.data.values[i] != "") {
                    const record = res.data.values[i]
                    if (record[22] == "NO") continue
                    let ref = record[1].match(/[\da-fA-F]{40}/gim)
                    if (ref) {
                      references = [...new Set([...references, ...ref])]
                    }
                  }
                }
                commit('SET_REFERENCES', references)
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        return new Promise((resolve) => { resolve(null); })
      }
    }
  },
  modules: {
  }
})
