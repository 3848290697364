import Vue from 'vue'
import VueMq from 'vue-mq'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faSearch,
         faExternalLinkAlt,
         faChevronUp,
         faBars,
         faAngleRight,
         faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faSearch,
  faExternalLinkAlt,
  faChevronUp,
  faBars,
  faAngleRight,
  faCaretDown
)
dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: Infinity
  }
})
const isProduction = process.env.NODE_ENV === 'production'
Vue.use(VueGtag,{
  config: { 
    id: "G-V0JBE1PXGQ",
    enabled: isProduction,
  }
},
router
)
Vue.config.productionTip = false

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iFrameResize(value, el))
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
