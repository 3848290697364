<template>
  <div :class="$mq == 'mobile'? 'full-width' : null">
    <div v-if="loading" class="text-center">
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>
    <div v-else class="container-fluid py-3 px-5">
      <div class="row">
        <h1 class="desc-bold">View the summary of findings table </h1>
        <span class="desc pb-5">Summary of findings tables were generated following the <a href="https://gdt.gradepro.org/app/handbook/handbook.html" target="_blank"> GRADE approach <font-awesome-icon icon="external-link-alt" style="width: 0.8em"/></a> including estimates of effect and certainty of the evidence for every assessed intervention in comparison to standard of care. </span>
        <div class="intervention-selector row mx-auto p-3">
          <h3 class="col-12 col-md-4 my-2 my-md-auto text-md-end " style="white-space: nowrap;"><strong >Select an intervention</strong></h3>
          <div class="col-12 col-md-8 my-auto">
            <multiselect v-model="selectedIntervention" placeholder="-- Select an intervention --" :options="interventions" :searchable="true" label="name" :allow-empty="true" @input="loadIsof">
              <template slot="option" slot-scope="{ option }" class="labels">{{ option.name }} <span class="empty-icon" v-if="isofs[option.name]=='no-isof'"> ∅ No iSoF available</span> </template>
              <template slot="singleLabel" slot-scope="{ option }" class="labels">{{ option.name }} <span class="empty-icon" v-if="isofs[option.name]=='no-isof'"> ∅ No iSoF available</span> </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <b-row v-if="ui.isofLoaded">
        <b-col>
          <!-- <h4 class="singisoftitle"> Summary of findings table of {{selectedIntervention}}</h4> -->
          <div v-if="isofs[selectedIntervention.name] != 'no-isof'">
            <div v-if="bottom_lines[selectedIntervention.name] != '' && $mq != 'mobile'" class="py-2 mb-3">
              <h5 class="bottomlinetitle"> Bottom line </h5>
              {{bottom_lines[selectedIntervention.name]}}
            </div>
            <div class="p-3">
              <iframe
                v-if="$mq == 'mobile'"
                class="my-4 text-center"
                :src="'https://m-isof.epistemonikos.org/#/finding/' + isofs[selectedIntervention.name]"
                width="100%"
                height="450px"
                frameborder="0"
                v-resize="{ log: false }"
              />
              <iframe
                v-else
                class="my-4 text-center"
                :src="'https://isof.epistemonikos.org/#/exportable_table/' + isofs[selectedIntervention.name] + '?no_scroll=true'"
                width="100%"
                frameborder="0"
                v-resize="{ log: false }"
              />
            </div>
          </div>

          <div v-else class="px-3 py-2 mb-3 mx-2 no-isof-bold">
            A Summary of Findings table is not provided because the available evidence is not informative.
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'SingularIsof',
  data () {
    return {
      interventions: [],
      options: [],
      selectedIntervention: null,
      isofIntervention: null,
      isofs: {},
      bottom_lines: {},
      loading: true,
      ui: {
        isofLoaded: false
      }
    }
  },
 mounted: async function() {
  await this.$store.dispatch('readSpreadsheet')
    .then(
      () => {
        let _interventions = [...this.$store.state.interventions].sort()
        this.isofs = this.$store.state.isofs
        this.interventions = []

        _interventions.map(i => {
          let _i = {name: i}
          if (this.isofs[i] == 'no-isof') {
            _i.$isDisabled = true
          }
          this.interventions.push(_i)
        })
        this.bottom_lines = this.$store.state.bottom_lines
        this.loading = false
      }
    ).catch(() => {
      this.loading = false
    })
  },
  methods: {
    loadIsof: function () {
      if (this.selectedIntervention) {
        this.isofIntervention = this.selectedIntervention
        this.ui.isofLoaded = true
      }
    }
  },
  components : {
    Multiselect
  }
}
</script>

<style lang="scss">
@import "../assets/styles/variables.scss";
  .multiselect__option--highlight, .multiselect__option--highlight:after{
    background-color: $ops-green !important;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
@import "../assets/styles/typography.scss";

.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}

.header {
  margin-top: 30px;
}

.header-text {
  font-weight: 700;
  font-size: 2rem;
}
.multiselect{
  .tags{
    display: flex;
  }
}
.box {
  border: 1px solid $line-box;
  border-radius: 3px;
  padding: 20px 10px;
  margin-bottom: 40px;
}

.desc-bold {
  // font-size: 1.43rem;
  margin-bottom: 1em;
  font-weight: bold;
}

.desc {
  font-size: 1.43rem;
  a {
    color: $ops-green;
    text-decoration: none;
    &:hover {
      color: $box-font-color;
    }
  }
}
.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.select-btn {
  background-color: $lsr-green;
  border-color: $lsr-green;
  padding: 7px 30px;
}

.singisoftitle{
  margin-top: 5vh;
  margin-left: 1vw;
  color: $lsr-green;
  font-weight: bold;
}

.bottomlinetitle{
  margin-top: 5vh;
  color: #007DC3;
  font-weight: bold;
}

.no-isof-bold {
  font-size: 1.23rem;
  font-weight: bold;
  margin-top: 5%;
}

.intervention-selector {
  border: 4px solid $lsr-green;
  padding: 30px 0px;
  border-radius: 15px;
  background-color: $light-grey;
  max-width: 900px;
}

// .form-arrow {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   background-image: url("data:image/svg+xml;utf8,<svg class='svg-icon' style='width: 0.48rem; height: 0.48rem;vertical-align: middle;fill: rgb(49,147,148);overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'><path d='M512 682.666667c-12.8 0-21.333333-4.266667-29.866667-12.8l-256-256c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733334 0l226.133333 226.133334 226.133333-226.133334c17.066667-17.066667 42.666667-17.066667 59.733334 0s17.066667 42.666667 0 59.733334l-256 256c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z'  /></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 98%;
//   background-position-y: 50%;
//   font-weight: bold;
//   background-color: #ececec;
//   border-radius: 2rem;
// }

.empty-icon {
 font-weight: bolder;
 right: 0;
 margin-left: 1rem;
 font-style: italic;

}

.labels {
  font-size: 1.43rem;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
