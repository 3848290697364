<template>
  <div>
    <b-container fluid class="ops-green px-2 pt-md-0">
      <!-- No hay traducción todavía -->
      <!-- <div class="ops-language-bar d-flex justify-content-end container px-4 pt-1 d-none d-md-flex">
        <b-button size="sm" class="text-dark rounded-0 py-0 px-3 ms-2">Português</b-button>
        <b-button size="sm" class="text-dark rounded-0 py-0 px-3 ms-2">Español</b-button>
        <b-button size="sm" class="text-dark rounded-0 py-0 px-3 ms-2">English</b-button>
      </div> -->
      <div class="p-2 pb-md-3 pt-md-3 d-none container d-md-flex justify-content-between">
        <img class="vhl-logo mr-3" :src="require(`../assets/images/bvs_logo_${'en'}.svg`)" alt="Virtual Health Library logo">
        <img class="img-fluid ops-logo" :src="require(`../assets/images/ops_logo_${'en'}.svg`)" alt="Pan American Health Organization logo" />
      </div>
      <div class="navbar-dark d-md-none">
        <b-navbar-toggle target="nav_collapse" class="ms-3 my-2 my-md-0 border-white">
          <template v-slot:default>
            <div>
              <i class="fas fa-bars"></i>
            </div>
          </template>
        </b-navbar-toggle>
      </div>
    </b-container>
    <b-container id="ops-portal-navbar" fluid class="">
      <b-navbar  class="container py-0 px-0  mx-auto" type="dark" toggleable="md">
        <b-collapse is-nav id="nav_collapse" class="py-3 p-md-0">
          <b-navbar-nav class="">
            <b-button size="sm" variant="link" :active="$route.path==='/'" class="rounded-0 text-start text-md-center" to="/">Home</b-button>
            <b-button size="sm" variant="link" :active="$route.path==='/summary'" class="rounded-0 text-start text-md-center" to='/summary'>Summary table</b-button>
            <b-button size="sm" variant="link" :active="$route.path==='/whats-new'" class="rounded-0 text-start text-md-center" to='/whats-new'>What's New</b-button>
            <b-button size="sm" variant="link" :active="$route.path==='/methods'" class="rounded-0 text-start text-md-center" to='/methods'>Methods</b-button>
            <b-button size="sm" variant="link" :active="$route.path==='/about'" class="rounded-0 text-start text-md-center" to='/about'>About</b-button>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'TopMenu',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    setLang (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/main.scss";

.ops-language-bar{
  .btn{
    background-color: $background;
    font-size: 0.7rem;
  }
}
#ops-portal-navbar{
  background-color: $ops-portal-dark;
  height: fit-content;
  .navbar {
    #nav_collapase{
      .navbar-nav{
        .ops-language-bar{
          .btn{
            background-color: $background;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .btn{
    background-color: $ops-portal-dark;
    color:$background;
    text-decoration: none;
    font-size: 0.8rem;
  }
  .btn:focus{
    box-shadow:none
  }
  .btn.active {
    background-color: #075a5a;
    text-decoration: none;
  }
  .btn.disabled{
    opacity: 1;
    color: $txt
  }
}
.ops-logo{
  max-height: 83px;
}
.vhl-logo{
  height: 14vw;
  max-height: 72px;
}
</style>
