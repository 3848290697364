<template>
  <div id="app">
    <top-menu/>
    <div class="view">
      <router-view/>
    </div>
    <footer-menu/>
    <div v-if="$route.name != 'Home'">
      <back-to-top-button/>
    </div>
  </div>
</template>

<script>

import TopMenu from './components/TopMenu'
import Footer from './components/Footer'
import BackToTopButton from './components/BackToTopButton'
export default {
  name: 'App',
  components: {
    'top-menu': TopMenu,
    'footer-menu': Footer,
    'back-to-top-button': BackToTopButton
  },
  created () {
    this.$store.dispatch('readSpreadsheet'),
    this.$store.dispatch('readExtraData') /* se busca leer la data de extra data que seria la fecha del ultimo update y el numero de estudios */,
    document.title = 'COVID-19 Therapeutic options',
    function () {
      if (typeof document !== 'undefined') {
        document.title = 'LSR-COVID-19'
      }
    }
  },
 }
</script>


<style lang="scss">
@import "./assets/styles/main.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.view {
  min-height: 81vh;
}
</style>
